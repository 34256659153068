import React from 'react';
import styled, { keyframes } from 'styled-components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const bounce = keyframes`
  0%, 20%, 50%, 80%, 100% { transform: translateY(0); }
  40% { transform: translateY(-30px); }
  60% { transform: translateY(-15px); }
`;

const HeroWrapper = styled.section`
  position: relative;
  min-height: 100vh;
  width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4rem 2rem;
  color: #fff;
  text-align: center;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)), url('/hero/hero.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: -1;
  }

  @media (max-width: 768px) {
    padding: 3rem 1.5rem;
    
    &::before {
      background-position: center center;
      background-attachment: initial;
      transform: scale(1.1);
    }
  }

  @media (max-width: 480px) {
    &::before {
      background-position: right top;
    }
  }
`;

const HeroContent = styled.div`
  max-width: 800px;
  margin: 0 auto;
  animation: ${fadeIn} 1s ease-out;
  position: relative;
  z-index: 2;
  padding: 0 1rem;
  width: 100%;

  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const Title = styled.h1`
  font-size: clamp(2rem, 5vw, 3rem);
  line-height: 1.2;
  margin-bottom: 1rem;
  color: #FFD700;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    margin-bottom: 0.75rem;
  }

  @media (max-width: 480px) {
    margin-bottom: 0.5rem;
  }
`;

const Subtitle = styled.p`
  font-size: clamp(1rem, 2.5vw, 1.5rem);
  line-height: 1.4;
  margin-bottom: 2rem;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);

  @media (max-width: 768px) {
    margin-bottom: 1.5rem;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 1rem;
  justify-content: center;
  flex-wrap: wrap;

  @media (max-width: 480px) {
    flex-direction: column;
    gap: 0.75rem;
  }
`;

const Button = styled.a`
  background-color: ${props => props.primary ? '#FFD700' : 'transparent'};
  color: ${props => props.primary ? '#000' : '#FFD700'};
  border: 2px solid #FFD700;
  padding: clamp(0.5rem, 2vw, 0.75rem) clamp(1rem, 3vw, 1.5rem);
  font-size: clamp(0.875rem, 1.5vw, 1rem);
  font-weight: bold;
  text-decoration: none;
  transition: all 0.3s ease;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    background-color: ${props => props.primary ? '#FFF' : '#FFD700'};
    color: #000;
    transform: translateY(-2px);
  }

  @media (max-width: 480px) {
    width: 100%;
    text-align: center;
  }
`;

const ScrollDownIcon = styled.div`
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
  font-size: clamp(1.5rem, 3vw, 2rem);
  color: #FFD700;
  animation: ${bounce} 2s infinite;
  cursor: pointer;
  z-index: 2;

  @media (max-width: 768px) {
    bottom: 1.5rem;
  }
`;

const HeroSection = () => {
  const scrollDown = () => {
    window.scrollTo({
      top: window.innerHeight,
      behavior: 'smooth'
    });
  };

  return (
    <HeroWrapper>
      <HeroContent>
        <Title>El verdadero bienestar es el equilibrio entre un cuerpo fuerte, una mente tranquila y un alma en paz</Title>
        <Subtitle>Entrenamientos totalmente individualizados para mejorar tu salud mental y fisica</Subtitle>
        <ButtonWrapper>
          <Button href="#" primary>Reserva tu primera sesión</Button>
          <Button href="#">Descubre nuestros programas</Button>
        </ButtonWrapper>
      </HeroContent>
      <ScrollDownIcon onClick={scrollDown}>
        <i className="fas fa-chevron-down"></i>
      </ScrollDownIcon>
    </HeroWrapper>
  );
};

export default HeroSection;
